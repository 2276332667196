<script lang="ts">
    import MyMarket from './MyMarket.svelte';
    import { inertia } from '@inertiajs/svelte';
    export let runningAuctions;

    console.log(runningAuctions);

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
</script>

<svelte:component this="{MyMarket}" />

<div class="table-wrapper px-3 pb-4">
    {#if runningAuctions.length > 0}
        <div>
            <div class="col d-flex align-items-center border-bottom py-3 font-bold">
                <div class="col-3 text-truncate">User</div>
                <div class="col-3 text-truncate">Last Bid Price</div>
                <div class="col-3 text-truncate">Expiry</div>
                <div class="col-3 text-truncate">Action</div>
            </div>
        </div>
        <div>
            {#each runningAuctions as runningAuction}
                <div class="col d-flex align-items-center border-bottom py-3">
                    <div class="col-3 text-truncate">
                        <a href="/{runningAuction.user.username}">{runningAuction.user.name}</a>
                    </div>
                    <div class="col-3 text-truncate">${runningAuction.latest_bid.amount}</div>
                    <div class="col-3 text-truncate">
                        {dateFormatter.format(new Date(runningAuction.end_time)) ?? '-'}
                    </div>
                    <div class="col-3 text-truncate">
                        <a class="btn btn-primary m-0" href="/markets" use:inertia>Visit</a>
                    </div>
                </div>
            {/each}
        </div>
    {:else}
        <p class="p-4 text-center">No Record Found</p>
    {/if}
</div>
